import {Module} from "vuex";
import PaymentMethodsDTO from "@/models/PaymentMethodsDTO";
import APIClient from "@/service/APIClient";
import PaymentOptionsDTO from "@/models/PaymentOptionsDTO";

const api = new APIClient();

const currencyMethods: Module<any, any> = {
    namespaced: true,
    state: {
        methods: [] as PaymentMethodsDTO[],
        options: [] as PaymentOptionsDTO[],
    },
    actions: {
        async loadList ({ state, commit }) {
            const methods = await api.paymentMethods();
            const options = await api.paymentOptions();

            commit('SET_LIST', {
                methods,
                options
            });
        },
    },
    mutations: {
        SET_LIST: (state, payload: any) => {
            state.methods = payload.methods;
            state.options = payload.options;
        },
    },
    getters: {
        currencies: (state): string[] => state.options.map((value:PaymentOptionsDTO) => value.currency),
        paymentSystems: (state) => (currency: string|null = null, option: string|null = null): string[] => {

            let systems = [];
            for (const item of state.methods) {
                for (const ps of item.paymentSystems) {
                    if (
                        !currency
                        || item.currency === currency && !option
                        || item.currency === currency && item.paymentOption === option
                    ) {
                        if (systems.indexOf(ps) === -1) {
                            systems.push(ps);
                        }
                    }
                }
            }

            return systems;
        },
        methods: (state) => (currency: string|null = null, option: string|null = null): string[] => {

            let methods = [];
            for (const item of state.methods) {
                for (const method of item.methods) {
                    if (
                        !currency
                        || item.currency === currency && !option
                        || item.currency === currency && item.paymentOption === option
                    ) {
                        if (methods.indexOf(method) === -1) {
                            methods.push(method);
                        }
                    }
                }
            }

            return methods;
        },
        options: (state) => (currency: string|null = null): string[] => {
            if (!currency) {
                let options = [];
                for (const item of state.options) {
                    for (const option of item.options) {
                        if (options.indexOf(option) === -1) {
                            options.push(option);
                        }
                    }
                }

                return options;
            }

            let currencyMethods = state.options.find((item: PaymentOptionsDTO) => item.currency === currency);
            if (!currencyMethods) {
                return []
            }

            return currencyMethods.options
        },
        list: (state): PaymentMethodsDTO[] => state.methods,
    }
}

export default currencyMethods;
