import {Module} from "vuex";
import TrafficGroupDTO from "@/models/TrafficGroupDTO";
import {api} from "@/service/APIClient";

const trafficGroups: Module<any, any> = {
    namespaced: true,
    state: {
        list: [] as TrafficGroupDTO[],
        loaded: false,
    },
    actions: {
        async loadList ({ state, commit }) {
            const groups = await api.call.trafficGroups.all()
            commit('SET_LIST', groups);
        },
    },
    mutations: {
        SET_LIST: (state, payload: TrafficGroupDTO[]) => {
            state.list = payload;
            state.loaded = true;
        },
    },
    getters: {
        get: (state) => (id: number): TrafficGroupDTO|undefined => {
          return state.list.find((group: TrafficGroupDTO) => group.id === id)
        },
        list: (state): TrafficGroupDTO[] => state.list,
    }
}

export default trafficGroups;
