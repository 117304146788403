import {Module} from "vuex";
import APIClient from "@/service/APIClient";
import TraderDeviceDTO from "@/models/TraderDeviceDTO";

const api = new APIClient();

const devices: Module<any, any> = {
    namespaced: true,
    state: {
        list: [] as TraderDeviceDTO[],
        loaded: false,
        promise: null,
    },
    actions: {
        async loadList ({ state, commit }) {
            let promise = api
                .devicesAll()
                .then(resp => {
                    commit('SET_LIST', resp);
                }).finally(() => {
                commit('SET_PROMISE', null);
            })

            commit('SET_PROMISE', promise);

            return promise;
        },
    },
    mutations: {
        SET_LIST: (state, payload: TraderDeviceDTO[]) => {
            state.list = payload;
            state.loaded = true;
        },
        SET_PROMISE: (state, payload) => {
            state.promise = payload;
        },
    },
    getters: {
        get: (state) => (id: string): TraderDeviceDTO|undefined => {
          return state.list.find((device: TraderDeviceDTO) => device.id === id)
        },
        ids: (state): string[] => state.list.map((device: TraderDeviceDTO) => device.id),
        list: (state): TraderDeviceDTO[] => state.list,
    }
}

export default devices;
