export enum TgNotification {
    NOTIFICATION_DEAL_CANCELED = 'deal_canceled',
    NOTIFICATION_DEAL_COMPLETED = 'deal_completed',
    NOTIFICATION_DEAL_CREATED = 'deal_created',
    NOTIFICATION_DEAL_DISPUTED = 'deal_disputed',
    NOTIFICATION_DEAL_NOT_FOUND = 'deal_not_found',
    NOTIFICATION_DEAL_TRANSFER_COMPLETED = 'deal_transfer_completed',
    NOTIFICATION_PAY_OUT_DEAL_ACCEPTED = 'pay_out_deal_accepted',
    NOTIFICATION_PAY_OUT_DEAL_ASSIGNED = 'pay_out_deal_assigned',
    NOTIFICATION_PAY_OUT_DEAL_REFUSED = 'pay_out_deal_refused',
}