import axios from "axios";
import TeamLeadRewardSettingsDTO from "@/models/TeamLeadRewardSettingsDTO";

export const teamLeadRewardSettings = {
    get: async (id: number): Promise<TeamLeadRewardSettingsDTO[]> => {
        let resp = await axios({
            url: `/traffic-groups/team-lead-reward-settings/${id}`,
            method: 'GET',
        });

        return resp.data as TeamLeadRewardSettingsDTO[]
    },
    byTrader: async (id: number): Promise<TeamLeadRewardSettingsDTO[]> => {
        let resp = await axios({
            url: `/traffic-groups/team-lead-reward-settings/by-trader/${id}`,
            method: 'GET',
        });

        return resp.data as TeamLeadRewardSettingsDTO[]
    },
    update: async (id: number, data: any): Promise<TeamLeadRewardSettingsDTO[]> => {
        let resp = await axios({
            url: `/traffic-groups/team-lead-reward-settings/${id}`,
            data,
            method: 'PUT',
        });

        return resp.data as TeamLeadRewardSettingsDTO[]
    }
}