import axios from "axios";
import TrafficGroupDTO from "@/models/TrafficGroupDTO";
import PaginatedResponseDTO from "@/models/PaginatedResponseDTO";
import TrafficGroupUpdateRequestDTO from "@/models/request/TrafficGroupUpdateRequestDTO";
import PaginationDTO from "@/models/PaginationDTO";

export const trafficGroups = {
    list: async (pagination: PaginationDTO, requestDTO: any = {}): Promise<PaginatedResponseDTO<TrafficGroupDTO>> => {
        let resp = await axios({
            url: `/traffic-groups`,
            data: {
                limit: pagination.limit,
                offset: pagination.offset,
                ...requestDTO
            },
            headers: {'X-HTTP-METHOD-OVERRIDE': "GET"},
            method: 'POST',
        });

        return resp.data as PaginatedResponseDTO<TrafficGroupDTO>
    },
    all: async (): Promise<TrafficGroupDTO[]> => {
        let resp = await axios({
            url: `/traffic-groups/all`,
            method: 'GET',
        });

        return resp.data as TrafficGroupDTO[]
    },
    create: async (data: TrafficGroupUpdateRequestDTO): Promise<TrafficGroupDTO> => {
        let resp = await axios({
            url: `/traffic-groups`,
            data,
            method: 'POST',
        });

        return resp.data as TrafficGroupDTO
    },
    update: async (id: number, data: TrafficGroupUpdateRequestDTO): Promise<TrafficGroupDTO> => {
        let resp = await axios({
            url: `/traffic-groups/${id}`,
            data,
            method: 'PUT',
        });

        return resp.data as TrafficGroupDTO
    }
}