import {Module} from "vuex";
import APIClient from "@/service/APIClient";

const api = new APIClient();

const rateSources: Module<any, any> = {
    namespaced: true,
    state: {
        rateSources: [] as string[],
    },
    actions: {
        async loadList ({ state, commit }) {
            const rateSources = await api.rateSources();

            commit('SET_LIST', {
                rateSources,
            });
        },
    },
    mutations: {
        SET_LIST: (state, payload: any) => {
            state.rateSources = payload.rateSources;
        },
    },
    getters: {
        all: (state): string[] => state.rateSources,
    }
}

export default rateSources;
