import {createStore} from 'vuex'
import createPersistedState from "vuex-persistedstate";
import AccessTokenDTO from "@/models/AccessTokenDTO";
import TraderDTO from "@/models/TraderDTO";
import router from './../router'
import APIClient from "@/service/APIClient";
import currencyMethods from "@/store/currencyMethods";
import {UserRole} from "@/models/enum/UserRole";
import MerchantDTO from "@/models/MerchantDTO";
import UserDTO from "@/models/UserDTO";
import devices from "@/store/devices";
import merchantStores from "@/store/merchantStores";
import reqGroups from "@/store/reqGroups";
import rateSources from "@/store/rateSources";
import AppDataDTO from "@/models/AppDataDTO";
import {Direction} from "@/models/enum/Direction";
import FrontendAppDTO from "@/models/FrontendAppDTO";
import MerchantOperatorDTO from "@/models/MerchantOperatorDTO";
import trafficGroups from "@/store/trafficGroups";

function loadFromLocalStorage(key: string, def: any): any {
  try {
    const data = localStorage.getItem(key) || def;
    return JSON.parse(data) as any;
  } catch (e) {
    return null
  }
}

function loadTokenFromLocalStorage(): AccessTokenDTO | null
{
  return loadFromLocalStorage('auth', '') as AccessTokenDTO
}

function loadAppFromLocalStorage(): AppDataDTO | null
{
  return loadFromLocalStorage('app', '') as AppDataDTO
}

const auth = loadTokenFromLocalStorage();
const appData = loadAppFromLocalStorage();

const api = new APIClient()
export default createStore({
  state: {
    apps: [] as FrontendAppDTO[],
    auth: auth as AccessTokenDTO | null,
    user: null as TraderDTO | MerchantDTO | UserDTO | MerchantOperatorDTO | null,
    appData: appData as AppDataDTO | null,
    afLastId: loadFromLocalStorage('afLastId', null) as number|null,
    afHasNew: false as boolean,
  },
  mutations: {
    setApps(state, apps: FrontendAppDTO[]) {
      state.apps = apps
    },
    setAfLastId(state, lastId: number|null) {
      state.afLastId = Math.max(lastId ?? 0, state.afLastId ?? 0)
      localStorage.setItem('afLastId', JSON.stringify(state.afLastId));
    },
    setAfHasNew(state, hasNew: boolean) {
      state.afHasNew = hasNew
    },
    setAppData(state, appData: AppDataDTO) {
      state.appData = appData;
      localStorage.setItem('app', JSON.stringify({version: appData.version, ga_id: appData.ga_id}));
    },
    authenticate(state, token: AccessTokenDTO) {
      state.auth = token;
      localStorage.setItem('auth', JSON.stringify({token: token.token, refreshToken: token.refreshToken}));
    },
    removeAuth(state) {
      localStorage.removeItem('auth');
      state.auth = null;
      state.user = null;
      router.push({ name: 'login'})
    },
    setUser(state, user: TraderDTO | null) {
      state.user = user;
    },
    setUserStatus(state, status: any) {
      if (status.direction === Direction.PAY_IN) {
        (state.user as TraderDTO)!.isActive = status.isActive
      } else {
        (state.user as TraderDTO)!.isPayOutActive = status.isActive
      }
    }
  },
  actions: {
    async loadApps({ state, commit }) {
      const apps = await  api.appsAll()
      commit('setApps', apps);
    },
    async loadAppData({commit}) {
      try {
        const appData = await api.app()
        commit('setAppData', appData);
      } catch (e) {

      }
    },
    async loadUser({commit}) {
      try {
        const user = await api.me()
        commit('setUser', user);
      } catch (e) {

      }
    },
    afLastId({commit}, lastId: number|null) {
      commit('setAfLastId', lastId);
    },
    afHasNew({commit}, hasNew: boolean) {
      commit('setAfHasNew', hasNew);
    },
    logout({commit}) {
      commit('removeAuth');
    },
    toggleTraderStatus({commit}, status: any) {
      return api.changeMeStatus(status.isActive, status.direction).then(() => {
        commit('setUserStatus', status);
      }).catch(() => {

      });
    },
    login({commit}, credentials) {

      return api.tokenGet(credentials).then((accessToken) => {
        commit('authenticate', accessToken);
        commit('merchantStores/SET_CURRENT_STORE', null);
      }).catch((error) => {
        throw error;
      });
    },
    useAccessToken({commit}, accessToken: AccessTokenDTO) {
      commit('authenticate', accessToken);
      commit('merchantStores/SET_CURRENT_STORE', null);
    },
    setAppData({commit}, appData:AppDataDTO) {
      commit('setAppData', appData);
    }
  },
  getters: {
    getApps: state => state.apps,
    getAppsIds: state => state.apps.map(item => item.id),
    getAppById: state => (id: number|null): FrontendAppDTO|null|undefined => {
      if (!id) {
        return null
      }
      return state.apps.find(item => item.id === id)
    },
    getAfLastId: state => state.afLastId,
    getAfHasNew: state => state.afHasNew,
    isLoggedIn: state => state.auth !== null,
    getAppData: state => state.appData,
    getAuth: state => state.auth,
    getUser: state => state.user,
    getRole: state => state.user?.role,
    hasRole: state => (roles: string[]): boolean => {
      if (!state.user?.role) {
        return false;
      }
      return roles.includes(state.user!.role)
    },
    hasPermission: state => (permission: string): boolean => {
      if (!state.user?.permissions) {
        return false;
      }
      return state.user.permissions.includes(permission)
    },
    isTrader: state => state.user && state.user.role === UserRole.TRADER,
    isTeamLead: state => state.user && state.user.role === UserRole.TRADER_TEAM_LEAD,
    isOperator: state => state.user && state.user.role === UserRole.OPERATOR,
    isAdmin: state => state.user && state.user.role === UserRole.ADMIN,
    isMerchant: state => state.user && state.user.role === UserRole.MERCHANT,
    isMerchantOperator: state => state.user && state.user.role === UserRole.MERCHANT_OPERATOR,
    isReferral: state => state.user && state.user.role === UserRole.REFERRAL,
  },
  strict: true,
  plugins: [createPersistedState()],
  modules: {
    currencyMethods,
    devices,
    merchantStores,
    reqGroups,
    rateSources,
    trafficGroups
  }
})
