import {Module} from "vuex";
import APIClient from "@/service/APIClient";
import TraderRequisiteGroupDTO from "@/models/TraderRequisiteGroupDTO";

const api = new APIClient();

const reqGroups: Module<any, any> = {
    namespaced: true,
    state: {
        list: [] as TraderRequisiteGroupDTO[],
        loaded: false,
        promise: null,
    },
    actions: {
        async loadList ({ state, commit }) {
            let promise = api
                .traderRequisiteGroupsAll()
                .then(resp => {
                    commit('SET_LIST', resp);

                    return resp
                }).finally(() => {
                commit('SET_PROMISE', null);
            })

            commit('SET_PROMISE', promise);

            return promise;
        },
    },
    mutations: {
        SET_LIST: (state, payload: TraderRequisiteGroupDTO[]) => {
            state.list = payload;
            state.loaded = true;
        },
        SET_PROMISE: (state, payload) => {
            state.promise = payload;
        },
    },
    getters: {
        get: (state) => (id: number): TraderRequisiteGroupDTO|undefined => {
          return state.list.find((group: TraderRequisiteGroupDTO) => group.id === id)
        },
        ids: (state): number[] => state.list.map((group: TraderRequisiteGroupDTO) => group.id),
        list: (state): TraderRequisiteGroupDTO[] => state.list,
    }
}

export default reqGroups;
