<template>
  <header class="" id="header-main">
    <nav class="navbar navbar-main navbar-expand-lg shadow-sm navbar-light bg-white p-0" id="navbar-main">
      <div class="w-100">
        <button v-on:click="toggleMenu()" class="navbar-toggler order-lg-2 p-2 w-100" type="button" data-toggle="collapse"
                data-target="#navbar-main-collapse" aria-controls="navbar-main-collapse" aria-expanded="false"
                aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div :class="{show: menuIsOpen}" class="collapse navbar-collapse navbar-collapse-overlay order-lg-3  " style="top: 3rem; bottom: 1rem" id="navbar-main-collapse">
          <div class="position-relative float-end" style="display: none">
            <button class="navbar-toggler m-2" v-on:click="menuIsOpen = false"  type="button" data-toggle="collapse" data-target="#navbar-main-collapse"
                    aria-controls="navbar-main-collapse" aria-expanded="false" aria-label="Toggle navigation">
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="feather feather-x">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
          <ul class="navbar-nav ms-3  overflow-auto pe-3">
            <li v-if="currentStore" class="nav-item nav-item-spaced d-lg-block text-nowrap border-end pe-3 me-3">
              <span style="color: #191919;" class="nav-link text-capitalize-first">
                 {{ currentStore.name }} ({{currentStore.currency}})
              </span>
            </li>
            <li v-for="item in filterMenuItems(menu)" :key="item.route" class="nav-item nav-item-spaced d-lg-block text-nowrap">
              <router-link v-if="item.route !== 'back'"
                v-on:click="menuIsOpen = false"
                class="nav-link text-capitalize-first"
                :class="{active: isActive(item.route)}"
                :active-class="''"
                :exact-active-class="''"
                :to="{name: item.route, query: item.query}"
              >
                <span v-if="hasBadge(item)" class="position-relative">
                  {{ trans(`menu.items.${item.route}`) }}
                  <span class="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle ms-1 mt-1"></span>
                </span>
                <span v-else>
                  {{ trans(`menu.items.${item.route}`) }}
                </span>

              </router-link>
              <a v-else
                           @click.prevent="menuIsOpen = false; $router.back()"
                           class="nav-link text-capitalize"
                           :class="{active: isActive(item.route)}"
                      href="#"
              >
                <i class="fa fa-arrow-left"></i> {{ trans(`menu.items.${item.route}`) }}
              </a>
            </li>
          </ul>
          <ul class="navbar-nav align-items-lg-center d-lg-flex ms-3 ms-lg-auto  me-3">
            <li v-if="this.isTrader" class="nav-item mt-2 mb-2">
              <div class="d-flex flex-row align-items-center gap-2">
                <label for="traderStatus" class="badge" :class="{'bg-danger': !this.getUser.isActive, 'bg-success': this.getUser.isActive}">{{ trans('direction.in') }}</label>
                <div class="form-switch">
                  <input id="traderStatus" @click.prevent="toggleStatus(this.getUser ? !this.getUser.isActive : false, Direction.PAY_IN)" class="form-check-input" style="cursor: pointer" type="checkbox" role="switch" :checked="this.getUser ? this.getUser.isActive : false">
                </div>
              </div>
            </li>
            <li v-if="this.isTrader" class="nav-item mt-2 mb-2">
              <div class="d-flex flex-row align-items-center gap-2">
                <label for="traderPayOutStatus" class="badge" :class="{'bg-danger': !this.getUser.isPayOutActive, 'bg-success': this.getUser.isPayOutActive}">{{ trans('direction.out') }}</label>
                <div class="form-switch">
                  <input id="traderPayOutStatus" @click.prevent="toggleStatus(this.getUser ? !this.getUser.isPayOutActive : false, Direction.PAY_OUT)" class="form-check-input" style="cursor: pointer" type="checkbox" role="switch" :checked="this.getUser ? this.getUser.isPayOutActive : false">
                </div>
              </div>
            </li>
            <li v-if="this.isOperator || this.isAdmin" class="nav-item">
              <div class="d-flex flex-row align-items-center">
                <label class="badge" :class="this.isAdmin ? 'bg-warning' : 'bg-primary' ">{{ trans(`labels.role.badge.${this.getRole}`) }}</label>
              </div>
            </li>
            <li class="nav-item">
              <a class="nav-link text-nowrap" href="#" @click.prevent="doLogout()">
                {{ trans('labels.logout') }} ({{ this.getUser ? this.getUser.email : '' }})
              </a>
            </li>
          </ul>
        </div>

      </div>
    </nav>
  </header>


</template>

<style scoped>

@media (max-width: 991.98px) {
  .nav-link.active {
    background-color: initial;
  }
}


</style>

<script>
import {mapGetters} from 'vuex';
import {useRoute} from "vue-router";
import moment from "moment";
import {helper} from "@/service/CommonHelper";
import APIClient from "@/service/APIClient";
import {Direction} from "@/models/enum/Direction";

const api = new APIClient();

export default {
  data() {
    return {
      menuIsOpen: false,
      currentDate: moment(),
      currentDateUTC: moment().utc(),
    }
  },
  computed: {
    Direction() {
      return Direction
    },
    ...mapGetters([
      'getUser',
      'isTrader',
      'isOperator',
      'isMerchant',
      'isMerchantOperator',
      'isAdmin',
      'isTeamLead',
      'isReferral',
      'getRole'
    ]),
    ...mapGetters({
      currentStore: 'merchantStores/getCurrent'
    }),
    menu() {
      if (this.isTrader) {
        return [
          { route: 'dashboard'},
          { route: 'statistic'},
          { route: 'accountOperations'},
          { route: 'deals'},
          { route: 'requisites'},
          { route: 'settings'},
        ]
      }

      if (this.isTeamLead) {
        return [
          { route: 'dashboard'},
          { route: 'statistic'},
          { route: 'accountOperations'},
          { route: 'deals'},
          { route: 'traders'},
          { route: 'requisites'},
          { route: 'settings'},
        ]
      }

      if (this.isOperator) {
        return [
          { route: 'deals'},
          { route: 'traders'},
          { route: 'requisites'},
          { route: 'devices'},
          { route: 'routing'},
          { route: 'antiFraudAlerts'},
          { route: 'transfers'},
          { route: 'statistic'},
          { route: 'settings'},
        ]
      }


      if (this.isAdmin) {
        let currentStore = this.$store.getters['merchantStores/getCurrent']
        if (currentStore !== null) {
          return [
            { route: 'back'},
            { route: 'accountOperations', query: {storeId: currentStore.id} },
            { route: 'invoices', query: {storeId: currentStore.id} },
          ]
        }

        return [
          { route: 'users'},
          { route: 'statistic'},
          { route: 'wallets'},
          { route: 'antiFraudAlerts'},
          { route: 'routing'},
          { route: 'transfers'},
          { route: 'deals'},
          { route: 'requisites'},
          { route: 'requisitesGroups'},
          { route: 'devices'},
          { route: 'settings'},
        ]
      }

      if (this.isReferral) {
        return [
          { route: 'dashboard'},
          { route: 'statistic'},
          { route: 'accountOperations'},
          { route: 'deals'},
          { route: 'settings'},
        ]
      }

      if (this.isMerchant) {
        let currentStore = this.$store.getters['merchantStores/getCurrent']
        if (currentStore !== null) {
          return [
            { route: 'back'},
            { route: 'accountOperations', query: {storeId: currentStore.id} },
            { route: 'invoices', query: {storeId: currentStore.id} },
          ]
        }

        return [
          { route: 'dashboard' },
          { route: 'statistic'},
          { route: 'settings'},
        ]
      }

      if (this.isMerchantOperator) {
        let currentStore = this.$store.getters['merchantStores/getCurrent']
        if (currentStore !== null) {
          return [
            { route: 'back'},
            { route: 'accountOperations', query: {storeId: currentStore.id} },
            { route: 'invoices', query: {storeId: currentStore.id} },
          ]
        }

        return [
          { route: 'dashboard' },
          { route: 'statistic'},
          { route: 'settings'},
        ]
      }

      return [];
    }
  },
  methods: {
    checkAfCount: async function () {
      if (this.isAdmin || this.isOperator) {
        setTimeout(async () => {
          this.$store.dispatch('afHasNew', await api.antiFraudAlertsCount(this.$store.getters['getAfLastId']))
          this.checkAfCount();
        }, 30000);
      }
    },
    getDate: function () {
        this.currentDate = moment();
        this.currentDateUTC = moment().utc();
    },
    isActive: function (routeName) {
        const route = useRoute();
        return route.name === routeName;
    },
    toggleMenu: function () {
      this.menuIsOpen = !this.menuIsOpen;
    },
    navItemClass: function (item) {
      if (item.type === 'item') {
        return '';
      }
      for (let index in item.items) {
        if (item.items[index].route === this.$route.name) {
          return 'menu-open';
        }
      }
      return '';
    },
    hasBadge: function (item) {
      switch (item.route) {
        case 'antiFraudAlerts':
          return this.$store.getters['getAfHasNew']
        default:
          return false;
      }
    },
    filterMenuItems: function (items) {
      return items.filter(item => this.hasAccess(item));
    },
    doLogout: function () {
      this.$store.dispatch('logout');
    },
    toggleStatus: function (isActive, direction) {
      this.$store.dispatch('toggleTraderStatus', {
        isActive,
        direction
      });
    },
    hasAccess(item) {
      return true;
    },
    trans(key, params) {
      return helper.trans(key, params)
    }
  },
  mounted() {
    setInterval(this.getDate, 1000);
    this.checkAfCount()

  },
};
</script>
