import {Module} from "vuex";
import APIClient from "@/service/APIClient";
import MerchantStoreDTO from "@/models/MerchantStoreDTO";

const api = new APIClient();

const merchantStores: Module<any, any> = {
    namespaced: true,
    state: {
        list: [] as MerchantStoreDTO[],
        loaded: false,
        promise: null,
        currentStore: null as MerchantStoreDTO | null
    },
    actions: {
        async loadList ({ state, commit }) {
            let promise = api
                .storesAll()
                .then(resp => {
                    commit('SET_LIST', resp);
                }).finally(() => {
                commit('SET_PROMISE', null);
            })

            commit('SET_PROMISE', promise);

            return promise;
        },
        async loadCurrentStore({state, commit, }, storeId: number) {
            const store = state.list.find((store: MerchantStoreDTO) => store.id == storeId)
            commit('SET_CURRENT_STORE', store ?? await api.storeGet(storeId))
        },
        setStores({commit}, stores: MerchantStoreDTO[])  {
            commit('SET_LIST', stores)
        },
        setCurrentStore({commit}, store: MerchantStoreDTO)  {
            commit('SET_CURRENT_STORE', store)
        },
    },
    mutations: {
        SET_CURRENT_STORE: (state, store: MerchantStoreDTO) => {
            state.currentStore = store
        },
        SET_LIST: (state, payload: MerchantStoreDTO[]) => {
            state.list = payload;
            state.loaded = true;
        },
        SET_PROMISE: (state, payload) => {
            state.promise = payload;
        },
    },
    getters: {
        getCurrent: (state): MerchantStoreDTO|null => state.currentStore,
        get: (state) => (id: number): MerchantStoreDTO|undefined => {
          return state.list.find((store: MerchantStoreDTO) => store.id === id)
        },
        ids: (state): number[] => state.list.map((store: MerchantStoreDTO) => store.id),
        list: (state): MerchantStoreDTO[] => state.list,
    }
}

export default merchantStores;
