import MoneyDTO from "@/models/MoneyDTO";

class NumberFormatter {
    private intlFormatters = [] as Intl.NumberFormat[];

    public formatMoney(value: MoneyDTO,  subunit: number|null = null): string|null {
        if (subunit !== null) {
            return this.formatNumber(parseFloat(value.amount), subunit) + ' ' + value.currency;
        }
        return this.formatNumber(value) + ' ' + value.currency;
    }

    public formatNumber(value: number|null|MoneyDTO, subunit: number = 0): string|null {
        if (value !== null && typeof value === 'object') {
            if ('amount' in value && 'subunit' in value) {
                subunit = value.subunit
                value = Number(value.amount)
            } else {
                throw new Error("Invalid arguments");
            }
        } else if (value === null || isNaN(value)) {
            return null;
        }

        return this.getIntlFormatter(subunit).format(value).replace(/,/g, ' ')
    }

    private getIntlFormatter(minimumFractionDigits: number): Intl.NumberFormat {
        if (!this.intlFormatters[minimumFractionDigits]) {
            this.intlFormatters[minimumFractionDigits] = new Intl.NumberFormat('en-US', {
                minimumFractionDigits,
                maximumFractionDigits: minimumFractionDigits
            });
        }
        return this.intlFormatters[minimumFractionDigits]
    }
}

const numberFormatter = new NumberFormatter();

export {
    numberFormatter
}
