import axios from "axios";
import PaginatedResponseDTO from "@/models/PaginatedResponseDTO";
import PaginationDTO from "@/models/PaginationDTO";
import InternalTransferRequestDTO from "@/models/InternalTransferRequestDTO";

export const internalTransferRequests = {
    list: async (pagination: PaginationDTO, requestDTO: any = {}): Promise<PaginatedResponseDTO<InternalTransferRequestDTO>> => {
        let resp = await axios({
            url: `/internal-transfer-requests`,
            data: {
                limit: pagination.limit,
                offset: pagination.offset,
                ...requestDTO
            },
            headers: {'X-HTTP-METHOD-OVERRIDE': "GET"},
            method: 'POST',
        });

        return resp.data as PaginatedResponseDTO<InternalTransferRequestDTO>
    },
    create: async (data: any): Promise<InternalTransferRequestDTO> => {
        let resp = await axios({
            url: `/internal-transfer-requests`,
            data,
            method: 'POST',
        });

        return resp.data as InternalTransferRequestDTO
    },
    complete: async (id: number): Promise<void> => {
        let resp = await axios({
            url: `/internal-transfer-requests/${id}/complete`,
            method: 'POST',
        });
    },
    cancel: async (id: number): Promise<void> => {
        let resp = await axios({
            url: `/internal-transfer-requests/${id}/cancel`,
            method: 'POST',
        });
    },
}